import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { HeartIcon } from '@heroicons/react/24/solid'
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";


export default function Timeline(){
    return (
        <div className="container mx-auto">
            <VerticalTimeline>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="22 Ekim 2023 10:35"
                    iconStyle={{background: 'rgb(255 144 144)', color: '#fff'}}
                    icon={<HeartIcon/>}
                >
                    <div className="flex flex-col items-center">
                        <LazyLoadImage src="./images/02.jpeg" className="w-1/2"/>
                        <p className="text-sm text-black">
                           Seni ilk fiziksel olarak gördüğüm gün... Ruhen 4 Eylül kabul ediyorum.
                            Senin o dünyalar güzeli ceylan gözlerine dönüp baktım ya bukim ben 26 yaşımda bir çift gözde kendimi buldum. Küçük bir kız çocuğunun gözlerinde...
                            Kayalıklarda otururken uçsuz bucaksız ufuklara seninle birlikte bakmak o kadar anlamlı ve özelki benim için anlatamıyorum tam olarak kelimelerim kifayetsiz kalıyor.
                            İyi ki seni tanımışım, iyi ki bir an bile seninle ilgili konuda tereddüt etmemişim, iyi ki... ❤️
                        </p>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="28 Ekim 2023 10:35"
                    iconStyle={{background: 'rgb(255 144 144)', color: '#fff'}}
                    icon={<HeartIcon/>}
                >
                    <div className="flex flex-col items-center">
                        <div className="flex">
                            <LazyLoadImage src="./images/05.jpeg" className="w-1/2 object-cover"/>
                            <LazyLoadImage src="./images/5-1.jpeg" className="w-1/2 object-cover"/>
                        </div>
                        <p className="text-sm text-black">
                            Küçük bir kız çocuğunun gözlerine bakıp manzaraya karşı kurduğum hayalin ilk günü...
                            Ben bugün seninle hayal kurdum sevgilim. Geleceğimizi düşündüm, bizi düşündüm. İçinde senin olan hayallerin ne kadar güzel ve anlamlı olduğunu gördüm.
                            Gözlerine baktım. Seni ilk öptüğüm gün. Tenlerimizin bu denli ilk defa yakın olduğu ve seni her anlamda hissettiğim ilk gün...
                            Hiç bir zaman bu günü unutmayacağım. Ben bütün o güzel duygularımı bugün seninle ortaya tekrardan çıkarttım. Varlığına bin şükür.
                            Bu güzel Ay'a bakıp akşam seninle metronun orada el ele tutuşup kurduğumuz hayali gerçekleştireceğim. Bunun olacağına şüphem yok.
                            Bana sadece ve sadece sen lazımsın...

                        </p>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="5 Kasım 2023"
                    iconStyle={{background: 'rgb(255 144 144)', color: '#fff'}}
                    icon={<HeartIcon/>}
                >
                    <div className="flex flex-col items-center">
                        <LazyLoadImage src="./images/07.jpeg" className="w-1/2 object-cover"/>
                        <p className="text-sm text-black">
                            İlk Aile kahvaltımız. Bugün kendimi çok özel hissetmiştim. Böyle hep hayalini kurduğum bir kadın vardı. O kadın olduğunu bana kanıtladığın ilk gün...
                            Gerçekten söylüyorum sevgilim ben bugün çok ama çok mutluydum seninle. Hep mutluyum orası ayrı... Böyle hamarat, sevdiği adama masa hazırlayabilecek kabiliyette olman en önemliside
                            bizim için emek verip bu masayı kurman çok güzeldi be sevgilim. Emeklerine kurban olurum senin.
                        </p>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="11 Aralık 2023"
                    iconStyle={{background: 'rgb(255 144 144)', color: '#fff'}}
                    icon={<HeartIcon/>}
                >
                    <div className="flex flex-col items-center">
                        <LazyLoadImage src="./images/12.jpeg" className="w-1/2 object-cover"/>
                        <p className="text-sm text-black">
                            Yine çok güzel ve özel bir gün... Ben tatmadığım lezzetleri seninle tatmak, yaşayamadıklarımı seninle yaşamak istedim.
                            Evet bukim ben sadece seni istedim... Hayatımda ilk defa waffle yedim. Beni herkes bilir bir şeye net değilsem değilimdir. İçimden gelmiyorsa asla yapmam.
                            Hiç sevememiştim şeklini denemek istedim seninle... Hayat işte bazen bazı şeyler gerçekten düşündüğümüz gibi olmuyormuş. Seninle öğreniyorum bende.
                            Birlikte öğreniyoruz... Tadı ayrı güzeldi, sen ayrı güzeldin. Minnettarım.
                        </p>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="11 Aralık 2023"
                    iconStyle={{background: 'rgb(255 144 144)', color: '#fff'}}
                    icon={<HeartIcon/>}
                >
                    <div className="flex flex-col items-center">
                        <LazyLoadImage src="./images/12-1.jpeg" className="w-1/2 object-cover"/>
                        <p className="text-sm text-black">
                            Ben senin elini tuttuğumda sınırsız güce ulaşıyorum. Ben bir ömür boyu
                            bu eli bırakmayacağıma canı gönülden söz verdim. Babama ve sana sözüm var. Sen benim canımdan parçasın.
                            Sen benim sonsuzluğumsun. Ben senin elini tuttuğum sürece hiçbir şeyden korkma canım bukim. Ben her anında, her kararında, her zorlukta,
                            her iyi günde seninleyim. Elini sonsuza kadar tutuyorum ve seni sonsuza kadar çok seviyorum.
                        </p>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="24 Aralık 2023"
                    iconStyle={{background: 'rgb(255 144 144)', color: '#fff'}}
                    icon={<HeartIcon/>}
                >
                    <div className="flex flex-col items-center">
                        <LazyLoadImage src="./images/13.jpeg" className="w-1/2 object-cover"/>
                        <p className="text-sm text-black">
                            Yine çok mutlu olduğumuz bir gün daha... Bugün akşam eve gittiğimde seninle pazar kahvaltılarını hayal etmiştim.
                            Böyle evimizde güzel bir pazar kahvaltısında senin ellerinden hazırlanan kahvaltılar... Tarif edemiyorum bu hissiyatı bu hayali...
                            Hiçbir şeye değişmem. Senin olduğun hiçbir şeyi değişmem...
                        </p>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="31 Aralık 2023"
                    iconStyle={{background: 'rgb(255 144 144)', color: '#fff'}}
                    icon={<HeartIcon/>}
                >
                    <div className="flex flex-col items-center">
                        <LazyLoadImage src="./images/15.jpeg" className="w-1/2 object-cover"/>
                        <p className="text-sm text-black">
                           2023 yılının uğurlu geleceğini biliyordum. Ama gerçekten senin gibi bir uğuru bana getireceğini bilmiyordum.
                            Çok mutluyum seninle 2023 yılında tanıştığım için, çok mutluyum seninle bir yılı kapatıp yeni bir yıla geçiş yapabildiğim için ve yine çok mutluyum
                            ömrümde aradığım kadını bulduğum için...
                            2024 yılı bize güzel şeyler getirecek ve seninle her yıl daha iyiye gideceğiz. Oturmayanları oturtacağız, kötülükleri iyiliklere çevireceğiz,
                            bereketsizliği berekete, yaşayamadıklarımızı yaşanılabilir kılacağız.
                            Biz seninle büyük bir çınarı büyütüyoruz buketim. Ben bu çınarın gövdesiyim. Ama sen bu ağacın kökü ve dallarısın...
                            Köküme kastedenin canını alırım, dalımı kıranı yok ederim. Senin bir zerrene zarar gelsin yakarım bu dünyayı bunu sakın unutma.
                            Seni çok ama çok seviyorum.
                        </p>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="19 Ocak 2024"
                    iconStyle={{background: 'rgb(255 144 144)', color: '#fff'}}
                    icon={<HeartIcon/>}
                >
                    <div className="flex flex-col items-center">
                        <LazyLoadImage src="./images/20.jpeg" className="w-1/2 object-cover"/>
                        <p className="text-sm text-black">
                            Seni sevdim. Seni öyle bir sevdimki içimde öyle bir istek ve arzu varki seninle aile olmak istiyorum.
                            Güzel bir evliliğimiz olsun, boy boy çocuklar olsun, güzel bir evimiz olsun buketim. Bunun için sen nasıl adım atıyorsan ve mutluluğu paylaşıyorsak aynı
                            şekilde bu Cibonda adımı atıyor ve mutluluğunu seninle paylaşacak. Canımı dişime taktım ne var ne yoksa bir şekilde seninle birlikte olmak için her şeyi yapıyorum.
                            Savaşım hedeflerim için ama hedeflerimde ailem var... Sen benim ailemsin. Sen benim yüzümü güldürenimsin. Seninle kurduğum her hayali gerçekleştireceğim.
                            Bunun için elimden geleni yapıyorum. Kalbim...
                        </p>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="4 Şubat 2024"
                    iconStyle={{background: 'rgb(255 144 144)', color: '#fff'}}
                    icon={<HeartIcon/>}
                >
                    <div className="flex flex-col items-center">
                        <LazyLoadImage src="./images/24.jpeg" className="w-1/2 object-cover"/>
                        <p className="text-sm text-black">
                           Hoşlanmak, aşık olmak, sevmek... Sonsuza kadar sevmek...
                            <br />
                            Ben seni sonsuza kadar sevdim. Sen benim  arkadaşım, sırdaşım, hayallerim, karım, kadınım, küçük kızımsın...
                            Ben senin yanında çocuklar gibi şenim. Senin bu mutluluklarını, yaşama tutunuşunu gördükçe daha da çok mutlu oluyorum.
                            Seninle yaşama isteğim daha çok artıyor.
                            Seninle çok ama çok mutluyum. Sonsuza kadar içimizdeki çocuğu büyüteceğimize şüphem yok. Benim küçük ve tatlı kızım.
                        </p>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="14 Şubat 2024"
                    iconStyle={{background: 'rgb(255 144 144)', color: '#fff'}}
                    icon={<HeartIcon/>}
                >
                    <div className="flex flex-col items-center">
                        <LazyLoadImage src="./images/27.jpeg" className="w-1/2 object-cover"/>
                        <p className="text-sm text-black">
                            26 yıllık yaşamımda ben kimseye böyle gitmedim buketim. 14 Şubat 2024'de sana öyle bir koştum ki öyle bir heyecanla
                            geldim ki sana anlatamam. Zorluklar çıktı üzüldük ama geriyi hiç düşünmedim.
                            Hep çıkacak ve ben hiç bir zaman geriyi düşünmeyeceğim. Konu sen isen daha çok ileri gideceğim.
                            Seni mutlu edebilmek yüzünü bir kez olsun güldürmek için her şeyi yaparım. Sen bu ciboya güvendin ya bana inandın ya
                            ben daha ne isterim ki bukim... Benim canım sana feda olsun.
                        </p>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="16 Şubat 2024"
                    iconStyle={{background: 'rgb(255 144 144)', color: '#fff'}}
                    icon={<HeartIcon/>}
                >
                    <div className="flex flex-col items-center">
                        <div className="flex">
                            <LazyLoadImage src="./images/29.jpeg" className="w-1/2 object-cover"/>
                            <LazyLoadImage src="./images/32.jpeg" className="w-1/2 object-cover"/>
                        </div>
                        <p className="text-sm text-black">
                         Tarif edilemez bir gün... O kadar huzurlu ve anlamlı ki...
                            O mutlu oluş şekillerine bitiyorum. Seninle sonsuz mutluluğa buketim...
                        </p>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="3 Mart 2024"
                    iconStyle={{background: 'rgb(255 144 144)', color: '#fff'}}
                    icon={<HeartIcon/>}
                >
                    <div className="flex flex-col items-center">
                        <LazyLoadImage src="./images/46.jpeg" className="w-1/2 object-cover"/>
                        <p className="text-sm text-black">
                            İlk türbe ziyaretimiz. Bizi çağırdı buketim...
                        </p>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="24 Mart 2024"
                    iconStyle={{background: 'rgb(255 144 144)', color: '#fff'}}
                    icon={<HeartIcon/>}
                >
                    <div className="flex flex-col items-center">
                        <LazyLoadImage src="./images/59.jpeg" className="w-1/2 object-cover"/>
                        <p className="text-sm text-black">
                            Başımın tacı, gönlümün sultanı ilk ikea ziyaretimiz... Ailem, evim, yuvam, yarınım, yarim...
                            Seninle tıpkı bugünki gibi daha bir çok yerde gezip alışveriş yapacağız.
                        </p>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="6 Nisan 2024"
                    iconStyle={{background: 'rgb(255 144 144)', color: '#fff'}}
                    icon={<HeartIcon/>}
                >
                    <div className="flex flex-col items-center">
                        <LazyLoadImage src="./images/67.jpeg" className="w-1/2 object-cover"/>
                        <p className="text-sm text-black">
                            Ben hiç kimseye sana baktığım gibi bakmadım. Sana bakınca ışığımı görüyorum.
                            Sana bakınca kendimi görüyorum. İnce, narin, kırılgan... Bir o kadar bunları korumak için
                            sertleşmiş, gergin, asabi ama tertemiz bir kalbi var benim japonumun. Sana ilk baktığım gibi masum bakıyorum.
                            Ölene dek sana böyle bakacağım.
                        </p>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="6 Nisan 2024"
                    iconStyle={{background: 'rgb(255 144 144)', color: '#fff'}}
                    icon={<HeartIcon/>}
                >
                    <div className="flex flex-col items-center">
                        <LazyLoadImage src="./images/73.jpeg" className="w-1/2 object-cover"/>
                        <p className="text-sm text-black">
                            Seninle ilk şarabımız. Ben ne kadar şarap içsemde gönlüm sana baktığım ve gördüğüm şekilde hoş olmayacak.
                            Senin böyle mutlu olduğunu gördükçe dünyalar benim oluyor. Çünkü ben seni çıkarsız sevdim. Çıkarsız sevgide
                            saflık vardır bukim. Tertemiz seviyorum sen mutlu olunca mutlu oluyor sen üzülünce üzülüyorum. Büyük Ada'da geçirdiğimiz
                            bugünü hiç unutmayacağım. Seninle geçirdiğim her zaman çok değerli, sen benim için çok değerlisin.
                        </p>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="6 Nisan 2024"
                    iconStyle={{background: 'rgb(255 144 144)', color: '#fff'}}
                    icon={<HeartIcon/>}
                >
                    <div className="flex flex-col items-center">
                        <LazyLoadImage src="./images/80-0.jpeg" className="w-1/2 object-cover"/>
                        <p className="text-sm text-black">
                            Ufuklar... Gelecek... Hayaller... Hepsi ama hepsi seninle güzel sevgilim. Benim inci tanem, ay parçam.
                            Biz seninle en iyiye gideceğiz her ne zorluk olursa olsun sen benim kadınımsın ve ben seninle her şeyi aşacağım.
                            Sen bana yar oldun ben sana dünya olacağım.
                        </p>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="13 Nisan 2024"
                    iconStyle={{background: 'rgb(255 144 144)', color: '#fff'}}
                    icon={<HeartIcon/>}
                >
                    <div className="flex flex-col items-center">
                        <LazyLoadImage src="./images/81.jpeg" className="w-1/2 object-cover"/>
                        <p className="text-sm text-black">
                            Seninle ilk flört ettiğimiz dönemde mekanlar keşfedip gidip puanlarız demiştik ya bak buda gerçek oluyor.
                            Seninle her hayali gerçekleştireceğiz. Seninle her şeyi başaracağız. Bugünümüz de çok güzeldi tıpkı seninle her saniye mutluluğum gibi...
                            Çok başka seviyorum seni miniğim...
                        </p>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="13 Nisan 2024"
                    iconStyle={{background: 'rgb(255 144 144)', color: '#fff'}}
                    icon={<HeartIcon/>}
                >
                    <div className="flex flex-col items-center">
                        <LazyLoadImage src="./images/82.jpeg" className="w-1/2 object-cover"/>
                        <p className="text-sm text-black">
                            Seni deliler gibi seviyorum. Anılarımsın sen benim. ❤️
                        </p>
                    </div>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date="SONSUZA"
                    iconStyle={{background: 'rgb(255 144 144)', color: '#fff'}}
                    icon={<HeartIcon/>}
                >
                    <div className="flex flex-col items-center">
                        <LazyLoadImage src="./images/85.jpeg" className="w-1/2 object-cover"/>
                        <p className="text-sm text-black">
                            AY KARANLIK
                        </p>
                    </div>
                </VerticalTimelineElement>

            </VerticalTimeline>
        </div>
    )
}