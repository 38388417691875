export default function Content(){
    return (
        <div className="container mx-auto py-12 space-y-4 px-5">
            <h1 className="text-center text-base text-[#fdcee8] float-right">19 Nisan 2024
            </h1>
            <h1 className="text-center text-2xl lg:text-4xl text-[#fdcee8]">Duygularım </h1>
            <p>
                Ben küçük bir çocuktum. Çok temiz, masum, istekleri yerine getirilen, kendini sürekli geliştiren bir çocuktum.
                Sonra her şey bir anda ters gitmeye başladı. Çok zorluklar yaşadım. Var olan her şey gitti... Ulaşabildiğim her şey gitti...
                Küçük şeyler bile ulaşılmaz oldu. Çok zordu her şey çok zordu. Sabahları bal, kaymak yiyen çocuk sadece bir kuru peynir ile okula gitmek zorunda kalmıştı.
                Annem ve babam çalışıyordu ama durum çok kötüydü. Toparlamaya çalışıyorlardı. Bir birlerine tutundular. Ne olursa olsun biziz dediler. İşte ben onu gördükten sonra
                çok değiştim. İsyan ediyordum etmez oldum, kızıyordum kızmaz oldum. Razı geldim onlar için çocuktum ama anladım her şeyi. Dünyayı çok erken gördüm. Gerçekler farkındalık yaratırmış.
                Bunu çocuk yaşımda gördüm ve öğrendim. Standartta kalsaydık sorun olmazdı. Örneğin hep fakir olsaydık zaten bir farkındalık oluşmazdı. Hayatın gerçeğide yüzüne tokat gibi çarpmazdı.
                Çünkü bu çok farklı bir duygu. Bir insanın hayat standartları mevcut durumdan daha kötüye gidiyorsa yaşayabileceği en büyük tavmaları yaşarmış. Çok net gördüm ve öğrendim.
            </p>
            <p>
                Neyse ben bu şekilde hayatın gerçeklerini gördüm. Görürken hep kendime dayandım daha çocuktum. Sustum kendime döndüm.
                Küstüm kendime döndüm. Kızdım yine kendime döndüm. Kimseyi istemez oldum. Büyüyordum ama nefret duygum artıyordu. Duygularımı çok yoğun yaşamaya başlamıştım.
                Herkes bana çok asabisin ve gerginsin demeye başladıkça daha da kötüye gidiyordum. Kafamın içindekiler beni daha sinirli bir insan yapıyordu. Bunun nedenini çok kez düşündüm.
                Düşündükçe daha çok saplandım oraya. Bir gün büyük bir tartışma oldu alıp başımı gittim. Sahile vurdum kendimi. Denizin sesini dinledim, kuşların cıvıltısını. Anladım...
                Anladım ki gitmek lazımmış. Sakinleşmek için, dingin bir ruha sahip olabilmek için bir müddet gitmek lazımmış. Gittim... Uzaklara gittim buketim. 1 hafta gittim... Sonra geri döndüm.
                Hayat bana çok farklı şeyler öğretti. Artık kendi kendimi sakinleştirebiliyordum. Kaçacak alan yarattım kendime. İyi geldi. Denedim ve denedikçe daha iyiye gitti.
                Tam kapandımı yaraların dersen kapanmadı. Canını sıkıyor mu artık dersen sıkmıyor. Şimdi ben bunu sana niye anlattım. Çünkü sen benim gibisin. Sen tertemiz bir kız çocuğusun. Ben senin
                gözlerine vurulan bir aşık. Sen sıkışıp kalmış, anlaşılmamış, bastırılmış, kendini bulamamış bir kız çocuğusun ben ise seni anlayan, senin yaşadıklarını yaşayan ve bunları aşmış bir delikanlıyım.
                Ben sendeki ışığı sana ilk baktığımda gördüm. Gördüm ki sen gerçekten dürüstsün. Gördüm ki sen gerçekten benim aradığım o küçük kız çocuğusun. Bu evrende seni bulduğum için çok minnettarım.
            </p>
            <p>
                Senin şu an için kaçacak bir yerin olmadığını, kafa dinlemek istesen bile ruhunu bir türlü dinginleştiremediğini biliyorum. Ben sana bunu yaratamadığım için üzgünüm gerçekten çok üzgünüm maalesef
                benim elimde değil. Ama şunu bilmeni isterim ki duygularını en yakın şekilde dinliyor ve anlamak için çok çaba veriyorum. Sen böyle sıkışıp kalıyorsun ya şu hayata o kadar çok canım yanıyor ki
                özellikle bunu hak etmediğini biliyorum bununla birlikte bende öfkeleniyorum hayata. Bizim seninle bu hayattan alacaklarımız var ve biz bunu seninle birlikte başaracağız.
            </p>
            <p>
                Seni çok özlüyorum. Seni çok seviyorum. Seni canımdan ayırmıyorum. Hayallerimi, geleceğimi, her şeyimi sana bağladım. Sen düşersen... Düşemezsin... Ben varım artık.
                Artık geri kalan ömrüm seninle. Her an her saniye seninle. Sen benim evimsin. Sen benim kadınımsın. Seni ölesiye seviyorum canım sevgilim. ❤️♾️
            </p>
            <p>
                Ben yani cibon en güzel hayallerimi seninle kurdum. En güzel duygularımı şu hayatımda seninle yaşadım. Bazı bazı hatalarım oluyor. Biliyorum seni incitiyorum. Göremiyorum çünkü
                bende toyluk gösteriyorum. İnsanım... Canım... Sana şöyle söylemek istiyorum ben bu hayatta bir çok hata yaptım. Bir çok yanlış yaptım. Bu hatalarım ve yanlışlarım sayesinde başarılı olmaya yürüyorum.
                Her bir hata beni daha iyileştirdi. Her bir yanlış beni daha kötüsünden korudu. Seninle ilgili yaptığım hatalarda sana olan sevgimin gücüyle beni sana karşı daha çok iyileştiriyor.
                Göremediğim tüm noktaları görmemi, anlayamadığım duyguları anlamamı sağlıyor. Bunu yaşarken sen kırılıyorsun ve inciniyorsun farkettim. Geç oldu belki ama farkettim. Hayatımda hiçbir hata için pişman olmadım.
                Ama seninle ilgili hatalarımda pişman oluyorum. Keşke diyorum daha önce görebilseydim. Bilemedim... Göremedim... Tecrübem yok bu denli sevgiye ve sevilmeye. Bu denli özleme, hasrete hiç tecrübem yok.
                Seni anlamak için daha çok çaba veriyorum. Seninle güzel anılar için daha çok zaman ayırıyorum. Daha güzel günlerimiz için daha çok hayal kuruyorum ve çalışıyorum.
            </p>
            <p>
                Seninle ben yani biz... Biz çoktan olduk. Var olmayı artık geçtik biz biriz. Ben senin canınım sen benim dünyam. Nefesimsin... Seni sonsuza dek çok seviyorum ve seveceğim...
            </p>
            <p>
                Tartışmalarımız oluyor, sıkıntılı süreçlerden geçiyoruz ama gün sonunda birlikte mutluyuz. Olması gereken budur. Her gün gülüp mutlu olamazsın. Kimse olamaz. Hayatın bir dengesi vardır.
                Bugün mutsuz olabilirsin ama yarın muhakkak mutlu olacaksın. Ben seninle gerçekten şunu anladım ki bu hayatta her ne olursa olsun seninle aşarım. Ben seni çıkarsız sevmişim, sen çıkarsız benim yanımda olmuşsun.
                Bunu görebiliyorum. Gerçekten gözlerine bakınca beni nasıl sevdiğini görebiliyorum. Bana bu yeter. Gözünün içindeki sevda varya buketim işte onun için bir ömür boyu bedel öderim.
            </p>
            <p>
                6.Ayımızı doldurduk. Benim için önemli bir zaman dilimi gerçekten bunu sana dürüstçe söylemiştim. Sebepleri vardı. Artık ortadan kalktı. Ben sana bir ömür boyu söz veriyorum.
                Sözümü tekrardan yineliyorum. Hastalıkta ve sağlıkta, kötü günde ve iyi günde, varlıkta ve yoklukta, ne olursa olsun seni bırakmayacağım. Bir ömür boyu seninleyim. Sen benim eşimsin.
            </p>
            <p>
                Benim çok büyük hayallerim var buketim. Hiç birinden vazgeçmedim. Ama öteledim. Bunun yegane sebebi senin varlığın. Hayatıma gelip bena güç vermen. Beni öyle bir seviyorsun ki
                ben yoktan var edebilecek yaratıcı güce erişiyorum. İsteğim gerçekten isteğim işlerimi bir an önce oturtup seninle mutlu bir yuva kurabilmek. Herkesin bir zamanı vardır. Ben şuna inanıyorum
                evren benim zamanımda ki en doğru zamanda seni bana getirdi. Şükürler olsun senin varlığınla işlerimi iyileştirip en güzel şeyleri yapacağım. Buna sonuna kadar inanıyorum. Bize sonuna kadar inanıyorum.
            </p>
            <p>
                Duygularımın sonuna gelirken şunlardan son kez bahsedeyim. Ben senin gözlerine baktığımda içindeki küçük kız çocuğunu görüyorum ya hani işte o küçük kız çocuğu bana öyle bir bakıyor ki
                öyle bir mutluki dünyaları o küçük kız çocuğunun mutluluğuna değişmem. Senin mutluluğuna hiçbir şeyi değişmem. Sana verebildiklerim için mutluyum. Veremediklerim için daha çok çalışıyorum.
                Ben senin sonsuza kadar yanındayım eşim. Ben sen ne zaman nefes alamazsan nefesin olmaya varım. Sen ne zaman üzgünsen mutluluğun olmaya varım. Ben çiçeğimi yaşatacağım. Ben çiçeğimi bağ bahçe yapacağım.
                Ben japonumu güldürüp eğlendireceğim. Ve ben babama verdiğim sözü tutup seni bir ömür boyu kollarımın arasında huzurlu bir şekilde koruyup kollayacağım.
            </p>
            <p>
                Bizi ölüm bile ayıramaz...
            </p>
            <p>
                Avuç içlerinden öperim huzurum. -Cibo
            </p>
        </div>
    );
}