import React from 'react';
import { Swiper, div } from 'swiper/react';
import 'swiper/css';
import { Autoplay} from 'swiper/modules';
import Slider from "./Slider";
import { LazyLoadImage } from "react-lazy-load-image-component";

function SliderThree() {
    return (
        <div className="section grid grid-cols-2 lg:grid-cols-3 gap-10 bg-white px-10 py-12">
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/50.jpeg" className="object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/51.jpeg" className="object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/52.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/53.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/54.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/55.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/56.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/57.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/58.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/58-1.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/59.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/60.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/61.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/62.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/62-1.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/63.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/64.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/65.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/66.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/67.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/68.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/69.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/70.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/71.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/72.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/73.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/74.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/75.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/76.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/77.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/78.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/79.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/80-0.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/sonsuz.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/80.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/81.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/82.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/83.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/84.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
            <div className="border-8 border-white drop-shadow-2xl p-2 bg-white h-full w-full object-cover">
                <div className="h-full w-full">
                    <LazyLoadImage src="./images/85.jpeg" className=" object-cover h-full w-full"/>
                </div>
            </div>
        </div>

    )
}

export default SliderThree;