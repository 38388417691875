import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay} from 'swiper/modules';

function Slider() {
    return (
        <Swiper
            slidesPerView={window.innerWidth <= 768 ? 1 : 3} // Mobilde 2 slayt, diğer cihazlarda 4 slayt göster
            autoplay={{
                delay: 0,
                disableOnInteraction: true,
            }}
            loop={true}
            speed={5e3}
            modules={[Autoplay]}
            className="w-full z-50 h-screen"
        >
            <SwiperSlide className="border-8 border-black">
                <div className="h-full w-full">
                    <img src="./images/01.jpeg" className="object-cover h-full w-full"  />
                </div>
            </SwiperSlide>
            <SwiperSlide className="border-8 border-black">
                <div className="h-full w-full">
                    <img src="./images/02.jpeg" className="object-cover h-full w-full" />
                </div>
            </SwiperSlide>
            <SwiperSlide className="border-8 border-black h-full w-full object-cover">
                <div className="h-full w-full">
                    <img src="./images/03.jpeg" className=" object-cover h-full w-full" />
                </div>
            </SwiperSlide>
            <SwiperSlide className="border-8 border-black h-full w-full object-cover">
                <div className="h-full w-full">
                    <img src="./images/05.jpeg" className=" object-cover h-full w-full" />
                </div>
            </SwiperSlide>
            <SwiperSlide className="border-8 border-black h-full w-full object-cover">
                <div className="h-full w-full">
                    <img src="./images/04.jpeg" className=" object-cover h-full w-full" />
                </div>
            </SwiperSlide>
            <SwiperSlide className="border-8 border-black h-full w-full object-cover">
                <div className="h-full w-full">
                    <img src="./images/5-1.jpeg" className=" object-cover h-full w-full" />
                </div>
            </SwiperSlide>
            <SwiperSlide className="border-8 border-black h-full w-full object-cover">
                <div className="h-full w-full">
                    <img src="./images/5-2.jpeg" className=" object-cover h-full w-full" />
                </div>
            </SwiperSlide>
            <SwiperSlide className="border-8 border-black h-full w-full object-cover">
                <div className="h-full w-full">
                    <img src="./images/06.jpeg" className=" object-cover h-full w-full" />
                </div>
            </SwiperSlide>
            <SwiperSlide className="border-8 border-black h-full w-full object-cover">
                <div className="h-full w-full">
                    <img src="./images/07.jpeg" className=" object-cover h-full w-full" />
                </div>
            </SwiperSlide>
            <SwiperSlide className="border-8 border-black h-full w-full object-cover">
                <div className="h-full w-full">
                    <img src="./images/08.jpeg" className=" object-cover h-full w-full" />
                </div>
            </SwiperSlide>
            <SwiperSlide className="border-8 border-black h-full w-full object-cover">
                <div className="h-full w-full">
                    <img src="./images/09.jpeg" className=" object-cover h-full w-full" />
                </div>
            </SwiperSlide>
            <SwiperSlide className="border-8 border-black h-full w-full object-cover">
                <div className="h-full w-full">
                    <img src="./images/10.jpeg" className=" object-cover h-full w-full" />
                </div>
            </SwiperSlide>
            <SwiperSlide className="border-8 border-black h-full w-full object-cover">
                <div className="h-full w-full">
                    <img src="./images/11.jpeg" className=" object-cover h-full w-full" />
                </div>
            </SwiperSlide>
            <SwiperSlide className="border-8 border-black h-full w-full object-cover">
                <div className="h-full w-full">
                    <img src="./images/12.jpeg" className=" object-cover h-full w-full" />
                </div>
            </SwiperSlide>
            <SwiperSlide className="border-8 border-black h-full w-full object-cover">
                <div className="h-full w-full">
                    <img src="./images/12-1.jpeg" className=" object-cover h-full w-full" />
                </div>
            </SwiperSlide>
        </Swiper>
    )
}

 export default Slider;