export default function Footer(){
    return (
        <div className="flex justify-center py-12 space-x-5">
            <div className="flex flex-col items-center space-y-10">
                <h2 className="text-center text-2xl lg:text-4xl text-pink-300">Suskun</h2>
                <p className="text-center">
                    <br/> Sus, kimseler duymasın.
                    <br/> Duymasın ölürüm ha.
                    <br/> Aydım yarı gecede
                    <br/> Yeşil bir yağmur sonra...
                    <br/> Yağıyor yeşil.
                    <br/>
                    <br/> En uzak, o adsız ve kimselersiz,
                    <br/> O yitik yıldızda duyuyor musun?
                    <br/> Bir stradivarius inler kendi kendine,
                    <br/> Yayı, reçinesi, köprüsü yeşil.
                    <br/> Önce bendim diyor ve sonra benim...
                    <br/> Ölümsüz, güzel ve çetin.
                    <br/> Ezgisidir dolaşan bütün evreni,
                    <br/> Bilinen, bilinmeyen ıssızlıkları.
                    <br/> Canımı, tüylerimi sarmada şimdi
                    <br/> Kendi rüzgarıyla vurgun...
                    <br/> Sarıyor yeşil.
                    <br/>
                    <br/> Rüya, bütün çektigimiz.
                    <br/> Rüya kahrım, rüya zindan.
                    <br/> Nasıl da yılları buldu,
                    <br/> Bir mısra boyu maceram...
                    <br/> Bilmezler nasıl aradık birbirimizi,
                    <br/> Bilmezler nasıl sevdik,
                    <br/> İki yitik hasret,
                    <br/> İki parça can.
                    <br/> Çatladı yüreği çakmaktaşının,
                    <br/> Ağıyor gök kuşaklarının serinliğinde
                    <br/> Çağlardır boğulmuş bir su...
                    <br/> Ağıyor yeşil.
                    <br/>
                    <br/> Yivlerinde yeşil güller fışkırmış,
                    <br/> Susmuş bütün namlular...
                    <br/> Susmuş dağ,
                    <br/> Susmuş deniz.
                    <br/> Dünya mışıl-mışıl,
                    <br/> Uykular derin,
                    <br/> Yılan su getirir yavru serçeye,
                    <br/> Kısır kadin, maviş bir kız doğurmuş,
                    <br/> Memeleri bereketli ve serin...
                    <br/> Sağıyor yeşil.
                    <br/>
                    <br/> Aydım yarı gecede,
                    <br/> Neron, çocuk kitaplarında çirkin bir surat,&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <br/> Ve Sezarsa, bir ad, yıkıntılarda.
                    <br/> Ama hançer taşı sanki
                    <br/> Koca Kartaca!
                    <br/> Hani, kibrit suyu vermişlerdi üstüne
                    <br/> Bak nasıl alıyor, yigit,
                    <br/> Binlerce yıl da sonra
                    <br/> Alıyor yesil.
                    <br/>
                    <br/> Vurur dağın doruğundan
                    <br/> Atmacamın çalkara,
                    <br/> Yalın gölgesi.
                    <br/> Kuş vurmaz, tavşan almaz,
                    <br/> Ama aç, azgın
                    <br/> Köpek balıklarıydı parçaladığı
                    <br/> Bak, Tiber saygılı, suskun.
                    <br/> Bak nilüfer dizisi zinciri.
                    <br/> Bunlar bukağısı, kolbağlarıdır,
                    <br/> Cihanın ilk umudu, ilk sevgilisi,
                    <br/> Ve ilk gerillası Spartakus'un.
                    <br/> Susuyor yeşil.
                    <br/>
                    <br/> Sus, kimseler duymasın,
                    <br/> Duymasın, ölürüm ha.
                    <br/> Aymışam yarı gece,
                    <br/> Seni bulmuşam sonra.
                    <br/> <strong>Seni, kaburgamın altın parçası. ❤️♾️</strong>
                    <br/> Seni, dişlerinde elma kokusu.
                    <br/> Bir daha hangi ana doğurur bizi?
                    <br/>
                    <br/> Ruhum...
                    <br/> Mısra çekiyorum, haberin olsun.
                    <br/> Çarşılarin en küçük meyhanesi bu,
                    <br/> Saçları yüzümde kardeş, çocuksu.
                    <br/> Derimizin altında o olüm namussuzu...
                    <br/> Ve Ahmedin işi ilk rasgidiyor.
                    <br/> İlktir dost elinin hançersizliği...
                    <br/> Ağlıyor yeşil.
                </p>

                <h2 className="text-center text-2xl lg:text-4xl text-pink-300">Artık Bütün Dünyam Senin</h2>
                <img className="w-48 animate-pulse" src="./images/love.png"/>
                <h2 className="text-center text-2xl lg:text-4xl text-pink-300 ">Artık Bütün Dünyan Benim</h2>
                <p>Seni sonsuza kadar seviyorum. <br/> Sen sonsuza kadar benimsin! <br/> İyi ki benim
                    bukimsin. <br/> Canımdan
                    öte sevdiğim yarim. <br/>
                    Seni o güzel kalbinden öpüyorum ❤️ <br/>
                    Kaburgamın altın parçası... <br/>
                    CİBO</p>
            </div>
        </div>
    )
}