import './App.css';
import React from 'react';
import {useState, useEffect} from "react";
import Header from './component/Header';
import Timeline from './component/Timeline';
import SliderTwo from './component/SliderTwo';
import SliderThree from './component/SliderThree';
import Content from './component/Content.js';
import Footer from './component/Footer.js';

function App() {
    useEffect(() => {
        document.title = 'Kalbime Hoş Geldin Eşim';
    }, []);

    const [sifre, setSifre] = useState('');
    const dogruSifre = 'sonsuz';
    const [girisBasarili, setGirisBasarili] = useState(false);
    const [message, setMessage] = useState(false);
    const handleGiris = () => {
        if (sifre === dogruSifre) {
             setGirisBasarili(true);
        } else {
            setMessage(true);
            setTimeout(() => {
                setMessage(false);
            }, 5000);
        }
    };

    if (girisBasarili) {
        // Giriş başarılı ise belirli bileşenleri görüntüle
        return (
            <div>
                <Header />
                <Timeline />
                <SliderTwo />
                <Content />
                <SliderThree />
                <Footer />
            </div>
        );
    } else {
        // Giriş başarısız ise giriş formunu göster
        return (
            <div className="bg-gray-100">
                <div className="flex flex-col justify-center items-center h-screen space-y-10 z-50 relative">
                    <h1 className="text-4xl lg:text-6xl text-[#fdcee8] text-center mb-10 max-w-2xl">
                        Kalbime Giriş Yap!
                    </h1>
                    <input
                        className="w-96 drop-shadow-2xl py-2 rounded-lg text-center border-4 border-pink-200 focus:border-pink-200"
                        type="password"
                        placeholder="Seni bekliyorum şifreyi gir..."
                        value={sifre}
                        onChange={(e) => setSifre(e.target.value)}
                    />
                    <button className="bg-pink-300 py-4 px-12 rounded-lg text-black" onClick={handleGiris}>Giriş Yap
                    </button>
                    {message === true &&
                        <div className="bg-white px-2 py-2 rounded-lg text-red-800 duration-200 transition-all animate-bounce">
                            <p>Hatalı şifre girdin gülüm. Lütfen tekrar dene...</p>
                        </div>
                    }
                </div>
                <div className="absolute top-0 left-0 h-full w-full z-30">
                    <img src="./images/67.jpeg" className="h-full w-full object-cover blur-sm"/>
                </div>

            </div>
        );
    }
}

export default App;
