import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay} from 'swiper/modules';
import Slider from "./Slider";

function SliderTwo() {
    return (
        <div className="section w-full">
            <div className="overflow-hidden">
                <Swiper
                    slidesPerView={window.innerWidth <= 768 ? 1 : 3} // Mobilde 2 slayt, diğer cihazlarda 4 slayt göster
                    autoplay={{
                        delay: 0,
                        disableOnInteraction: true,
                    }}
                    loop={true}
                    speed={5e3}
                    modules={[Autoplay]}
                    className="w-full z-50 h-96"
                >
                    <SwiperSlide className="border-2 border-black">
                        <div className="h-full w-full  bg-black">
                            <img src="./images/13.jpeg" className="object-cover h-full w-full"/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black">
                        <div className="h-full w-full ">
                            <img src="./images/14.jpeg" className="object-cover h-full w-full"/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/15.jpeg" className=" object-cover h-full w-full"/>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/16.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/17.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/18.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/19.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/19-1.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/20.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/21.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/22.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/23.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/24.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/25.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/26.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/27.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/28.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/29.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/30.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/31.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/32.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/33.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/34.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>

                </Swiper>

                <Swiper
                    slidesPerView={window.innerWidth <= 768 ? 1 : 3}
                    autoplay={{
                        delay: 0,
                        reverseDirection: true
                    }}
                    loop={true}
                    speed={5e3}
                    modules={[Autoplay]}
                    className="w-full z-50 h-96"
                >
                    <SwiperSlide className="border-2 border-black">
                        <div className="h-full w-full ">
                            <img src="./images/35.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/36.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/37.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/38.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/39.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/40.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/41.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/42.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/43.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/44.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/45.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/46.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/47.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/48.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="border-2 border-black ">
                        <div className="h-full w-full ">
                            <img src="./images/49.jpeg" className=" object-cover h-full w-full" />
                        </div>
                    </SwiperSlide>

                </Swiper>
            </div>
        </div>
    )
}

 export default SliderTwo;