import React from "react";
import Slider from './Slider';
import ReactAudioPlayer from "react-audio-player";

export  default  function Header() {
    return (
        <>
            <ReactAudioPlayer
                src="./images/Cem-Karaca-Ay-Karanlik.mp3"
                autoPlay={true}
                controls={false}
                loop={true}
            />
            <div className="section w-full h-screen relative">
                <div className="absolute opacity-80 z-50 h-full w-full bg-black"></div>
                <div className="absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2 overflow-hidden z-50">
                    <h1 className="text-4xl lg:text-6xl text-[#fdcee8] text-center mb-10 max-w-2xl">DÜNYAMA HOŞ GELDİN
                        SEVGİLİM!</h1>
                    <div className="flex items-center justify-center">
                        <img className="w-48 animate-pulse" src="./images/loveSlide.png"/>
                    </div>
                    <div className="text-base text-white text-center max-w-2xl">

                    </div>
                </div>
                <div className="relative top-1/2 -translate-y-1/2 overflow-hidden">
                    <Slider/>
                </div>
            </div>
        </>

    )
}